<template>
  <div class="app-container">
    <img
      src="https://img10.360buyimg.com/ddimg/jfs/t1/154147/9/11170/204007/5fe25e05Eb43de4ab/11eed6fe3dc5a939.jpg"
    />
    <van-form @submit="onSubmit">
      <van-cell-group class="block">
        <van-field
          v-model="form.realname"
          label="真实姓名"
          placeholder="请输入真实姓名"
          left-icon="manager-o"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="form.mobile"
          type="number"
          placeholder="请输入手机"
          label="手机号码"
          left-icon="phone-o"
          :rules="[{ required: true }]"
        />
        <van-field
          readonly
          clickable
          is-link
          v-model="form.region"
          placeholder="请选择地区"
          label="所在地区"
          @click="bindShow"
          left-icon="location-o"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="form.memo"
          rows="4"
          autosize
          label="申请理由"
          type="textarea"
          placeholder="请输入"
          left-icon="notes-o"
          :rules="[{ required: true }]"
        />
      </van-cell-group>
      <div class="btn">
        <van-button type="info" block native-type="submit">立即申请</van-button>
      </div>
    </van-form>

    <van-popup v-model="showArea" position="bottom" :style="{ height: '40%' }">
      <van-area
        :area-list="areaList"
        @confirm="onAreaConfirm"
        @cancel="bindCancel"
        value="320500"
      />
    </van-popup>
  </div>
</template>
<script>
import AeraInfo from '@/utils/area.js'
import { Notify } from 'vant'
export default {
  data () {
    return {
      areaList: AeraInfo,
      form: {
        new_client_type: 3
      },
      showArea: false
    };
  },
  mounted () {
    this.setClientType()
  },
  methods: {
    setClientType () {
      if (this.$route.query.sale) {
        this.form.new_client_type = 3
      }else{
        this.form.new_client_type = 2
      }
    },
    bindShow () {
      this.showArea = true;
    },
    bindCancel () {
      this.showArea = false;
    },
    //地区选择
    onAreaConfirm (val) {
      this.showArea = false;
      this.arrArea = val;
      var addrInfo = val[0].name + '-' + val[1].name + '-' + val[2].name;
      this.form.region = addrInfo
    },
    onSubmit () {
      this.$axios.post('/wxc/client/apply', this.form).then(res => {
        this.form = {}
        this.setClientType()
        Notify({ type: 'success', message: res.msg })
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.btn {
  padding: 0 20px 0;
}
</style>
